.ReactFlipCard {
    margin: 25px;
    text-align: center;
}

.ReactFlipCard,
.ReactFlipCard__Front,
.ReactFlipCard__Back {
    box-sizing: border-box;
    width: 280px;
    height: 300px;
}

.ReactFlipCard__Front,
.ReactFlipCard__Back {
    border-radius: 10px;
    border: 1px solid #ccc;
}

.ReactFlipCard__Front {
    background-color: #eee;
}

.ReactFlipCard__Back {
    background-color: #eee;
}


.custom-scroll .inner-handle {
    background-color: grey;
}

.react-confirm-alert-overlay {
    background: rgba(255, 255, 255, 0.2) !important;
    opacity: 0.01 !important;
}
.Select-menu-outer {
    height: 410px !important;
    overflow-y: visible !important;
    max-height: 410px !important;
}

.Select-menu {
    max-height: 410px !important;
}

.stro-button.stro-right-button span {
    margin-top: 30px !important;
}
